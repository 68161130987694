.zone-indicator{
    /*background: red;*/
    border-radius: 10rem;
    float: left;
    padding: 7px;
    margin: 2px;
}

.border-selected {
    border: 2px solid #000 !important;
}